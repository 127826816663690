<template>
  <div>
    <div class="top">用户授权协议</div>

    <p>
      《用户授权协议》(以下简称“本协议”)是北京投哪儿信息技术有限公司（以下简称“本公司”）华夏数艺平台（以下简称“本平台”））与用户（以下简称“您”）所订立的有效合约。您通过网络页面点击确认或以其他方式选择接受本协议，即表示您与本公司已达成协议并同意接受本协议的全部约定内容。
    </p>
    <p>
      在接受本协议之前，请您仔细阅读本协议的全部内容（特别是以粗体下划线标注的内容）。如您不同意本协议的内容，或无法准确理解本协议任何条款的含义，请不要进行确认及后续操作。一旦确认即视为您同意本协议全部内容。如果您对本协议有疑问的，请通过平台微信客服进行询问，其将向您解释。
    </p>
    <p>
      1、您不可撤销的授权本公司将您本人的身份信息资料提交给第三方电子签名服务商和第三方CA认证机构，由本公司协助您向第三方电子签名服务商申请由ＣＡ认证机构生成、第三方电子签名服务商代发的数字证书。如因您提供的申请数字证书的身份信息有误或冒用他人身份信息导致不利后果，由您承担因此产生的全部法律责任。
    </p>
    <p>
      2、您同意本平台采用电子合同的方式在本平台与您签订您与本平台进行交易需订立的协议，该协议可以有一份或者多份并且每一份具有同等法律效力。您根据有关协议及本网站的相关规则在本网站确认签署的电子合同即视为您本人真实意愿并以您本人名义签署的合同，具有法律效力。您应妥善保管自己的账户密码以及手机验证码等账户信息，您通过前述方式订立的电子合同对合同各方具有法律约束力，您不得以账户密码或手机验证码等账户信息被盗用或其他理由否认已订立的合同的效力或不按照该等合同履行相关义务。
    </p>
    <p>
      3、您在此不可撤销的承诺，授权北京投哪儿信息技术有限公司出于向您本人提供授权服务之需要，可以在华夏数艺平台调用您本人的电子签章（CA证书），以您的名义签订并代为保管与_各类____服务相关协议实质内容相同的文件，该调用行为视为您本人的操作，因调用您本人电子签章（CA证书）产生的一切法律后果由您本人自行承担，您放弃追究北京投哪儿信息技术有限公司或其委托的第三方合作机构以及第三方CA机构任何法律责任的权利。
    </p>
    <p>
      4、您认可您在签订电子合同过程中提交的实名认证资料，并认可因上述操作产生的电子证据；若因合同履行产生纠纷，您认可本合同签订过程中产生的电子证据可直接作为纠纷处理依据。
    </p>
    <p>
      5、双方在履行本协议的过程中，如发生争议，应协商解决。协商不成的，任何一方均可向地方法院提起诉讼。
    </p>

    <div class="box_but">
      <div class="but_no" @click="cancel">取消</div>
      <div class="but_confirm" @click="confirm">确定</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: '',
    };
  },
  mounted() {
    this.id = this.$route.query.id;
  },
  methods: {
    cancel() {
      this.$router.push('/Autonym');
    },
    confirm() {
      let params = this.id;
      this.$api.anyIdcardadd(params).then((res) => {
        if (res.code == 0) {
          // this.anyIdcardadd = res.msg;
          window.location.href = res.data.originalUrl;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.top {
  text-align: center;
}
.box_but {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  bottom: 0px;
  .but_no {
    background-color: #ffffff;
    width: 165px;
    color: #0754d3;
    height: 40px;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    position: fixed;
    bottom: 5px;
    left: 5px;
  }
  .but_confirm {
    background-color: #0754d3;
    width: 165px;
    color: #ffffff;
    height: 40px;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    border-radius: 5px;
    position: fixed;
    bottom: 5px;
    right: 5px;
  }
}
</style>
